@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600&display=swap');

:root {
  --primary-color: #B85A24;
  --body-background-color: #FEFEFE;
  --background-color: #F7F3F0;
  --title-color: #131313;
  --text-color: #7D7D7D;
}

body {
  background-color: var(--body-background-color);
}

h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', serif; 
  color: var(--title-color);
}

div {
  overflow-y: hidden;
}

h1 {
  color: var(--primary-color);
  background-color: inherit;
}

h2 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

p, a, li {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
}

li {
  list-style-image: url(./assets/liIcon.svg);
}

span {
  color: var(--primary-color);
}

footer {
  background-color: var(--background-color);

  .line-break {
    height: 1px;
    background-color: var(--primary-color);
  }
}

.root {
  display: grid;
  gap: 0px;
  @media (min-width: 1024px) {
    gap: 100px;
  }
}

.benefits-section {
  background-color: var(--background-color);
}